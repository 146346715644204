

























































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "Integrations",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.Integrations.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Integrations.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  computed: {
    types() {
      return types;
    },
  },
  data: () => ({
    integrationIcons: [
      {
        height: 25,
        icon: require("../assets/integrations/partners/amazon.svg"),
      },
      {
        height: 55,
        icon: require("../assets/integrations/partners/partner1.png"),
      },
      {
        height: 32,
        icon: require("../assets/integrations/partners/wooCommerce.svg"),
      },
      {
        height: 25,
        icon: require("../assets/integrations/partners/jmuia.svg"),
      },
      { height: 24, icon: require("../assets/integrations/partners/noon.svg") },
      { height: 25, icon: require("../assets/integrations/partners/mumz.svg") },
      { height: 23, icon: require("../assets/integrations/partners/wix.svg") },
      {
        height: 45,
        icon: require("../assets/integrations/partners/partner9.png"),
      },
      {
        height: 35,
        icon: require("../assets/integrations/partners/partner12.svg"),
      },
    ],
  }),
});
